import React, {Fragment, useEffect} from 'react';
import 'react-alice-carousel/lib/alice-carousel.css'

function Ressources() {

    useEffect(() => {
        window.scroll(0, 0)
    }, []);
    const handleDragStart = (e) => e.preventDefault();
    const responsive = {
        0: {items: 1},
        568: {items: 2},
    };

    return (
        <Fragment>
            <div>
                <div className="rogan-hero-section rogan-hero-four pt-250 pb-150 md-pt-200 md-pb-130 pos-r">
                    <div className="shape-wrapper">
                        <svg className="img-shape shape-two">
                            <path fillRule="evenodd" fill="rgb(255, 223, 204)"
                                  d="M6.000,12.000 C9.314,12.000 12.000,9.313 12.000,6.000 C12.000,2.686 9.314,-0.000 6.000,-0.000 C2.686,-0.000 -0.000,2.686 -0.000,6.000 C-0.000,9.313 2.686,12.000 6.000,12.000 Z"/>
                        </svg>
                        <svg className="img-shape shape-three">
                            <path fillRule="evenodd" fill="rgb(198, 255, 224)"
                                  d="M12.500,24.999 C19.403,24.999 25.000,19.403 25.000,12.500 C25.000,5.596 19.403,-0.001 12.500,-0.001 C5.596,-0.001 -0.000,5.596 -0.000,12.500 C-0.000,19.403 5.596,24.999 12.500,24.999 Z"/>
                        </svg>
                        <svg className="img-shape shape-four">
                            <path fillRule="evenodd" opacity="0.451" fill="rgb(255, 126, 190)"
                                  d="M10.000,20.000 C15.523,20.000 20.000,15.522 20.000,10.000 C20.000,4.477 15.523,-0.000 10.000,-0.000 C4.477,-0.000 -0.000,4.477 -0.000,10.000 C-0.000,15.522 4.477,20.000 10.000,20.000 Z"/>
                        </svg>
                        <svg className="img-shape shape-five">
                            <path fillRule="evenodd" fill="rgb(181, 198, 255)"
                                  d="M6.000,12.000 C9.314,12.000 12.000,9.313 12.000,6.000 C12.000,2.686 9.314,-0.000 6.000,-0.000 C2.686,-0.000 -0.000,2.686 -0.000,6.000 C-0.000,9.313 2.686,12.000 6.000,12.000 Z"/>
                        </svg>
                        <svg className="img-shape shape-six">
                            <path fillRule="evenodd" fill="rgb(171, 247, 242)"
                                  d="M12.500,25.000 C19.404,25.000 25.000,19.403 25.000,12.500 C25.000,5.596 19.404,-0.000 12.500,-0.000 C5.596,-0.000 -0.000,5.596 -0.000,12.500 C-0.000,19.403 5.596,25.000 12.500,25.000 Z"/>
                        </svg>
                    </div>
                    <div className="container">
                        <div className="main-wrapper pos-r">
                            <h1 className="banner-main-title underline pt-15 pb-45 md-pt-10 md-pb-30 wow fadeInUp  animated"
                                data-wow-delay="0.4s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.4s",
                                    animationName: "fadeInUp"
                                }}>Ressources Disponibles</h1>
                            <div className="col-md-6 text-left">
                                <ul className="">
                                 <li>


                                       <a target="_blank" href="assets/ressources/certificat_qualiopi.pdf">Certificat qualiopi 
                                        ISC
                                        FORMATION</a></li>
                                    <li>

                                        <a target="_blank" href="assets/ressources/charte_qualite_formation.pdf">Charte qualité
                                        ISC
                                        FORMATION</a></li>
                                    <li><a target="_blank" href="assets/ressources/charte_pedagogique_isc_formation.pdf">Charte
                                        pédagogique d’ISC FORMATION</a></li>
                                    <li><a target="_blank" href="assets/ressources/cgv_isc_formation.pdf">CGV ISC FORMATION</a>
                                    </li>
                                    <li><a target="_blank" href="assets/ressources/cgu_isc_formation.pdf">CGU ISC
                                        FORMATION </a>
                                    </li>
                                    <li><a target="_blank" href="assets/ressources/reglement_interieur_isc_formation.pdf">Réglement
                                        interieur ISC FORMATION </a></li>
                                    <li><a target="_blank" href="assets/ressources/rgpd_isc_formation.pdf">RGPD ISC
                                        FORMATION</a>
                                    </li>
                                    <li><a target="_blank" href="assets/ressources/personnes_en_situation_handicap.pdf">Les
                                        personnes en situation d'handicap ISC FORMATION </a></li>
                                    <li><a target="_blank" href="assets/ressources/formulaire-reclamation.pdf">Formulaire de
                                        réclamation ISC FORMATION </a></li>
                                </ul>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </Fragment>
    )
}

export default Ressources
