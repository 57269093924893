import React, {Fragment, useEffect,useState,useRef} from 'react';
import AliceCarousel from 'react-alice-carousel';

import 'react-alice-carousel/lib/alice-carousel.css';
import googlereview from './googlereview/googlereview.json';

function Home() {
    const ref = useRef(null);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const $ = window.jQuery;
        const handleScroll = () => {
            const { top, bottom } = ref.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            if (top < windowHeight && bottom > 0) {
                const timer = $(ref.current);
                $(timer[0]).countTo("restart");
                const timer1 = $(ref1.current);
                $(timer1[0]).countTo("restart");
                const timer2 = $(ref2.current);
                $(timer2[0]).countTo("restart");
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.scroll(0, 0);
        function watchScroll() {
            window.addEventListener("scroll", handleScroll);
        }
        watchScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const handleDragStart = (e) => e.preventDefault();
    const responsive = {
        0: {items: 1},
        568: {items: 2},
    };

    const items = googlereview.reviews.map((review) => {
        return (
            <div className="owl-item cloned" style={{
                width: "400px",
                marginRight: "0px"
            }}>
                <div className="item">
                    <div className="customer-content">
                        <img src="images/logo/c-1.png" alt=""/>
                        <p className="pt-15 pb-10 review-text">{review.text}</p>
                        <div className="d-flex align-items-center">
                            <img src={review.profile_photo_url} alt="" className="c-img"/>
                            <div>
                                <h6>{review.author_name}</h6>
                                <div>{new Intl.DateTimeFormat('fr-FR', {
                                    year: 'numeric',
                                    day: '2-digit',
                                    month: '2-digit'
                                }).format(review.time * 1000)}</div>
                                <span className="span-review" aria-label="Note&nbsp;: 5,0 sur&nbsp;5" role="img">
                                    <span style={{width: 100 * review.rating / 5 + "%"}}></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    });
    return (
        <Fragment>
            <div>
                <div className="rogan-hero-section rogan-hero-four pt-250 pb-150 md-pt-200 md-pb-130 pos-r">
                    <div className="shape-wrapper">
                        <img src="assets/images/shape/50.svg" alt="" className="img-shape bg-shape"/>
                        <svg className="img-shape shape-two">
                            <path fillRule="evenodd" fill="rgb(255, 223, 204)"
                                  d="M6.000,12.000 C9.314,12.000 12.000,9.313 12.000,6.000 C12.000,2.686 9.314,-0.000 6.000,-0.000 C2.686,-0.000 -0.000,2.686 -0.000,6.000 C-0.000,9.313 2.686,12.000 6.000,12.000 Z"/>
                        </svg>
                        <svg className="img-shape shape-three">
                            <path fillRule="evenodd" fill="rgb(198, 255, 224)"
                                  d="M12.500,24.999 C19.403,24.999 25.000,19.403 25.000,12.500 C25.000,5.596 19.403,-0.001 12.500,-0.001 C5.596,-0.001 -0.000,5.596 -0.000,12.500 C-0.000,19.403 5.596,24.999 12.500,24.999 Z"/>
                        </svg>
                        <svg className="img-shape shape-four">
                            <path fillRule="evenodd" opacity="0.451" fill="rgb(255, 126, 190)"
                                  d="M10.000,20.000 C15.523,20.000 20.000,15.522 20.000,10.000 C20.000,4.477 15.523,-0.000 10.000,-0.000 C4.477,-0.000 -0.000,4.477 -0.000,10.000 C-0.000,15.522 4.477,20.000 10.000,20.000 Z"/>
                        </svg>
                        <svg className="img-shape shape-five">
                            <path fillRule="evenodd" fill="rgb(181, 198, 255)"
                                  d="M6.000,12.000 C9.314,12.000 12.000,9.313 12.000,6.000 C12.000,2.686 9.314,-0.000 6.000,-0.000 C2.686,-0.000 -0.000,2.686 -0.000,6.000 C-0.000,9.313 2.686,12.000 6.000,12.000 Z"/>
                        </svg>
                        <svg className="img-shape shape-six">
                            <path fillRule="evenodd" fill="rgb(171, 247, 242)"
                                  d="M12.500,25.000 C19.404,25.000 25.000,19.403 25.000,12.500 C25.000,5.596 19.404,-0.000 12.500,-0.000 C5.596,-0.000 -0.000,5.596 -0.000,12.500 C-0.000,19.403 5.596,25.000 12.500,25.000 Z"/>
                        </svg>
                    </div>
                    <div className="container">
                        <div className="main-wrapper pos-r">
                            <h1 className="banner-main-title underline pt-15 pb-45 md-pt-10 md-pb-30 wow fadeInUp  animated"
                                data-wow-delay="0.4s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.4s",
                                    animationName: "fadeInUp"
                                }}>OPTEZ POUR LA QUALITE <br/>EN CHOISISSANT<br/>ISC FORMATION
                            </h1>
                            <p className="banner-sub-title pb-30 md-pb-30 wow fadeInUp  animated" data-wow-delay="0.9s"
                               style={{
                                   visibility: "visible",
                                   animationDelay: "0.9s",
                                   animationName: "fadeInUp"
                               }}>
                                Vous souhaitez devenir chauffeur VTC, TAXI ou encore VMDTR ? <br/>Notre centre de
                                formation vous prépare à l'examen théorique <br/>et pratique et nos équipes vous propose
                                un accompagnement <br/>individualisé du début à la fin de votre parcours.
                            </p>
                            {/**
                            <div className="btn-box">
                                <a data-fancybox="" href="https://www.youtube.com/embed/vVXAXDvgPiQ"
                                   className="video-btn fancybox video-button button-orange video-button-one wow fadeInRight  animated"
                                   data-wow-delay="1.5s"
                                   style={{
                                       visibility: "visible",
                                       animationDelay: "1.5s",
                                       animationNname: 'fadeInRight'
                                   }}><span> <i className="flaticon-play-button"></i></span>Nos parcours en vidéos.</a>
                            </div>

                            <div _ngcontent-qcp-c63="" className="btn-box"><a _ngcontent-qcp-c63=""
                                                                              href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                                                                              target="_blank"
                                                                              className="video-btn"><span
                                _ngcontent-qcp-c63=""><i _ngcontent-qcp-c63=""
                                                         className="bx bx-play-circle"></i></span> Watch Video</a></div>
                            **/}
                        </div>
                    </div>
                    <div className="shape-wrapper screen-wrapper">
                        <div className="screen-one img-shape wow fadeInRight animated" data-wow-delay="0.4s"
                             data-wow-duration="2s">
                            <img id="homepage-img" style={{
                                width: "50%",
                                float: "right"
                            }} src="assets/images/illustration/formation-vtc-taxi-taxi-moto-elearning.png"
                                 alt="Formation VTC, TAXI, TAXI-MOTO"/>
                        </div>
                    </div>
                </div>
                <section className="container certifications mt-20">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-3 col-sm-12 text-center mb-4 mb-md-0">
                            <img src="assets/images/cpf/cpf-100.png" alt=""/>
                        </div>
                        <div className="col-md-3 col-sm-12 text-center mb-4 mb-md-0">
                            <img src="assets/images/qualiopi/qualiopi.png" alt=""/>
                        </div>
                        <div className="col-md-3 col-sm-12 text-center mb-4 mb-md-0">
                            <img src="assets/images/prefecture/formation-taxi-vtc-carte-pro-prefecture.png" alt=""/>

                        </div>
                        <div className="col-md-3 col-sm-12 text-center mb-4 mb-md-0">
                            <img src="assets/images/cma/cma_idf.png" alt=""/>
                        </div>
                    </div>
                </section>
                <div className="section-full pb-5 ">
                    <div className="container">
                        <div className="headline ">
                            <p className="text-center headline-text ">
                                La prochaine session d'examen aura lieu Mardi 29 juin 2021 Date. limite d'inscription :
                                Vendredi 28 mai 2021 inclus.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="about-us-block-two pt-50 pb-50 md-pt-150 md-pb-100 pos-r">
                    <div className="inner-wrapper pos-r">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 order-lg-last">
                                    <div className="theme-title-one">
                                        <div className="upper-title mb-5 color-orange">Qui sommes-nous</div>
                                        <h2 className="main-title">ISC FORMATION</h2>
                                    </div>
                                    {/* /.theme-title-one */}
                                    {/* <div className="mark-text pt-25 pb-35">Creative & Proffesional Digital</div> */}
                                    <p className="pb-25">ISC formation vous apporte les clés de la réussite à travers un
                                        programme personnalisé qui vise à vous garantir la réussite lors de votre examen
                                        de chauffeur VTC, TAXI et VMDTR.
                                        Avec notre centre, bénéficiez de notre programme de qualité et de nos conseils,
                                        à chaque étape de votre parcours.</p>

                                    <p>Nos équipes vous transmettront leur savoir faire en s’appuyant sur une pédagogie
                                        et un enseignement de qualité, fruits de nombreuses années de pratique sur le
                                        terrain.
                                        Elles vous fourniront les outils indispensables à votre succès futur.
                                        Avec notre centre de formation agrée pour VTC, TAXI et MOTO TAXI, lancez votre
                                        activité et adoptez les codes de la réussite.</p>
                                </div>
                                <div className="col-xl-6  col-lg-6 ml-auto">
                                    <div className="theme-counter-five md-mt-70">
                                        <div className="inner-wrapper pos-r">
                                            <div className="shape-wrapper">
                                                <img src="assets/images/shape/dot-shape2.svg" alt=""
                                                     className="shape-dot img-shape"/>
                                                <svg className="img-shape dot-one">
                                                    <path fillRule="evenodd" fill="rgb(125, 236, 114)"
                                                          d="M4.500,9.000 C6.985,9.000 9.000,6.985 9.000,4.500 C9.000,2.014 6.985,-0.001 4.500,-0.001 C2.015,-0.001 -0.000,2.014 -0.000,4.500 C-0.000,6.985 2.015,9.000 4.500,9.000 Z"/>
                                                </svg>
                                                <svg className="img-shape dot-two">
                                                    <path fillRule="evenodd" fill="rgb(255, 181, 106)"
                                                          d="M13.000,26.000 C20.180,26.000 26.000,20.179 26.000,12.999 C26.000,5.820 20.180,-0.001 13.000,-0.001 C5.820,-0.001 -0.000,5.820 -0.000,12.999 C-0.000,20.179 5.820,26.000 13.000,26.000 Z"/>
                                                </svg>
                                                <svg className="img-shape dot-three">
                                                    <path fillRule="evenodd" fill="rgb(136, 217, 255)"
                                                          d="M4.500,8.999 C6.985,8.999 9.000,6.985 9.000,4.500 C9.000,2.014 6.985,-0.000 4.500,-0.000 C2.015,-0.000 -0.000,2.014 -0.000,4.500 C-0.000,6.985 2.015,8.999 4.500,8.999 Z"/>
                                                </svg>
                                                <svg className="img-shape dot-four">
                                                    <path fillRule="evenodd" fill="rgb(255, 108, 196)"
                                                          d="M10.000,20.000 C15.523,20.000 20.000,15.522 20.000,10.000 C20.000,4.477 15.523,-0.000 10.000,-0.000 C4.477,-0.000 -0.000,4.477 -0.000,10.000 C-0.000,15.522 4.477,20.000 10.000,20.000 Z"/>
                                                </svg>
                                                <svg className="img-shape dot-five">
                                                    <path fillRule="evenodd" fill="rgb(119, 244, 245)"
                                                          d="M4.500,8.999 C6.985,8.999 9.000,6.985 9.000,4.500 C9.000,2.014 6.985,-0.000 4.500,-0.000 C2.015,-0.000 -0.000,2.014 -0.000,4.500 C-0.000,6.985 2.015,8.999 4.500,8.999 Z"/>
                                                </svg>
                                                <svg className="img-shape dot-six">
                                                    <path fillRule="evenodd" fill="rgb(165, 149, 255)"
                                                          d="M15.000,30.000 C23.284,30.000 30.000,23.284 30.000,15.000 C30.000,6.715 23.284,-0.000 15.000,-0.000 C6.716,-0.000 -0.000,6.715 -0.000,15.000 C-0.000,23.284 6.716,30.000 15.000,30.000 Z"/>
                                                </svg>
                                                <svg className="img-shape dot-seven">
                                                    <path fillRule="evenodd" fill="rgb(102, 225, 158)"
                                                          d="M5.500,11.000 C8.537,11.000 11.000,8.537 11.000,5.500 C11.000,2.462 8.537,-0.001 5.500,-0.001 C2.462,-0.001 -0.000,2.462 -0.000,5.500 C-0.000,8.537 2.462,11.000 5.500,11.000 Z"/>
                                                </svg>
                                            </div>
                                            {/* /.shape-wrapper */}
                                            <div className="counter-wrapper"  >
                                                <div className="single-counter-box bx-one"
                                                     style={{background: 'rgba(0,161,255,0.54)'}}>
                                                    <h2 className="number" ><span ref={ref} className="timer" data-from={0}
                                                                                 data-to={9000}
                                                                                 data-speed={1200}
                                                                                 data-refresh-interval={5}>0</span>
                                                    </h2>
                                                    <p>HEURES DE FORMATION</p>
                                                </div>
                                                {/* /.single-counter-box */}
                                                <div className="single-counter-box bx-two"
                                                     style={{background: 'rgba(255,173,58,0.67)'}}>
                                                    <h2 className="number"><span ref={ref1} className="timer" data-from={0}
                                                                                 data-to={920} data-speed={1200}
                                                                                 data-refresh-interval={5}>0</span></h2>
                                                    <p>STAGIAIRES FORMÉS</p>
                                                </div>
                                                {/* /.single-counter-box */}
                                                <div className="single-counter-box bx-three"
                                                     style={{background: 'rgba(140,39,255,0.54)'}}>
                                                    <h2 className="number"><span ref={ref2} className="timer" data-from={0}
                                                                                 data-to={95.5} data-speed={1200}
                                                                                 data-refresh-interval={5}>0</span>%
                                                    </h2>
                                                    <p>CLIENTS SATISFAITS</p>
                                                </div>
                                                {/* /.single-counter-box */}
                                            </div>
                                            {/* /.counter-wrapper */}
                                        </div>
                                    </div>
                                    {/* /.theme-counter-five */}
                                </div>
                            </div>
                            <div className="row mt-60">
                                <div className="col">
                                    <div className=" d-flex align-items-center ">
                                        <div className="wheel p-3 rounded ">
                                            <i className="fas fa-wheelchair "/>
                                        </div>
                                        <div className=" wheel-text ">
                                            Si vous êtes en situation de handicap et vous souhaitez suivre l'une de nos
                                            formations, faites-le nous savoir, nous mettrons tout en œuvre pour trouver
                                            une solution adaptée.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /.container */}
                    </div>
                    {/* /.inner-wrapper */}
                </div>

                {/* 
                            =============================================
                                Our Service One
                            ============================================== 
                            */}
                <section className="our-service-three pt-60 pos-r pb-15 md-pb-10 md-pt-10">
                    <div className="shape-wrapper">
                        <img src="assets/images/home/shape1.png" alt="" className="img-shape shape-one"/>
                        <svg className="img-shape shape-two">
                            <path fillRule="evenodd" fill="rgb(168, 255, 243)"
                                  d="M7.500,14.999 C11.642,14.999 15.000,11.642 15.000,7.500 C15.000,3.357 11.642,-0.001 7.500,-0.001 C3.358,-0.001 -0.000,3.357 -0.000,7.500 C-0.000,11.642 3.358,14.999 7.500,14.999 Z"/>
                        </svg>
                        <svg className="img-shape shape-three">
                            <path fillRule="evenodd" fill="rgb(255, 164, 164)"
                                  d="M15.000,30.000 C23.284,30.000 30.000,23.284 30.000,15.000 C30.000,6.715 23.284,-0.000 15.000,-0.000 C6.716,-0.000 -0.000,6.715 -0.000,15.000 C-0.000,23.284 6.716,30.000 15.000,30.000 Z"/>
                        </svg>
                        <svg className="img-shape shape-four">
                            <path fillRule="evenodd" fill="rgb(255, 216, 142)"
                                  d="M5.000,10.000 C7.761,10.000 10.000,7.761 10.000,5.000 C10.000,2.238 7.761,-0.000 5.000,-0.000 C2.238,-0.000 -0.000,2.238 -0.000,5.000 C-0.000,7.761 2.238,10.000 5.000,10.000 Z"/>
                        </svg>
                        <svg className="img-shape shape-five">
                            <path fillRule="evenodd" fill="rgb(142, 244, 229)"
                                  d="M7.500,15.000 C11.642,15.000 15.000,11.642 15.000,7.499 C15.000,3.357 11.642,-0.000 7.500,-0.000 C3.358,-0.000 -0.000,3.357 -0.000,7.499 C-0.000,11.642 3.358,15.000 7.500,15.000 Z"/>
                        </svg>
                        <svg className="img-shape shape-six">
                            <path fillRule="evenodd" opacity="0.541" fill="rgb(255, 252, 231)"
                                  d="M171.500,343.000 C266.217,343.000 343.000,266.216 343.000,171.500 C343.000,76.783 266.217,-0.001 171.500,-0.001 C76.783,-0.001 -0.000,76.783 -0.000,171.500 C-0.000,266.216 76.783,343.000 171.500,343.000 Z"/>
                        </svg>
                    </div>
                    {/* /.shape-wrapper */}
                    <div className="container">
                        <div className="theme-title-one text-center pb-50 md-pb-30">
                            <h2 className="main-title">Nos Formations</h2>
                            <hr className="title-divider wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms"
                                style={{
                                    visibility: 'visible',
                                    animationDuration: '1000ms',
                                    animationDelay: '300ms',
                                    animationName: 'fadeInUp'
                                }}/>
                        </div>
                        {/* /.theme-title-one */}
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-12 col-lg-4 js-scroll">
                                <div className="feature-block-three mt-50">
                                    <div className="libelle-formation">VTC</div>
                                    <div className="header-formation">
                                        <img src="assets/images/illustration/formation-vtc-elearning.png"/>
                                    </div>
                                    <div className="brief-formation">
                                        Nos équipes de spécialistes du métier de VTC vous accompagnent tout au long de
                                        votre formation afin de garantir votre réussite aux examens théorique et
                                        pratique.
                                    </div>
                                    <div>
                                        <div className="text-center mb-3 mt-3">
                                            <div>
                                                <a href="https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/81534405600016_VTC-2022/81534405600016_VTC_FEV2022?contexteFormation=ACTIVITE_PROFESSIONNELLE"
                                                   target="_blank"
                                                   className="theme-btn line-button-one contact-button button-orange">Financez
                                                    par votre CPF</a>
                                            </div>
                                        </div>
                                        <div className="text-center mb-3 mt-3">
                                            <div>
                                                <a href="/contact.html"
                                                   className="theme-btn line-button-one contact-button button-orange">En
                                                    savoir plus<i
                                                        className="fa fa-angle-right" aria-hidden="true"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-12 col-lg-4 js-scroll">
                                <div className="feature-block-three mt-50">
                                    <div className="libelle-formation">TAXI</div>
                                    <div className="header-formation">
                                        <img src="assets/images/illustration/formation-taxi-elearning.png"/>
                                    </div>
                                    <div className="brief-formation">
                                        Devenez chauffeur de taxi parisien en optant pour notre formation en
                                        e-elearning, l'ensemble de notre corps pédagogique sera présent pour de répondre
                                        à vos interrogations.
                                    </div>
                                    <div>
                                        <div className="text-center mb-3 mt-3">
                                            <div>
                                                <a href="https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/81534405600016_TAXI_2022/81534405600016_TAXI_FEV2022?contexteFormation=ACTIVITE_PROFESSIONNELLE"
                                                   target="_blank"
                                                   className="theme-btn line-button-one contact-button button-orange">Financez
                                                    par votre CPF</a>
                                            </div>
                                        </div>
                                        <div className="text-center mb-3 mt-3">
                                            <div>
                                                <a href="/contact.html"
                                                   className="theme-btn line-button-one contact-button button-orange">En
                                                    savoir plus<i
                                                        className="fa fa-angle-right" aria-hidden="true"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-12 col-lg-4 js-scroll">
                                <div className="feature-block-three mt-50">
                                    <div className="libelle-formation">TAXI-MOTO</div>
                                    <div className="header-formation">
                                        <img src="assets/images/illustration/formation-taxi-moto-elearning.png"/>
                                    </div>
                                    <div className="brief-formation">
                                        Avec plus de la moitié de la flotte de VMDTR formés au sein de notre
                                        établissement, ISC FORMATION est l'un des pionniers sur le secteur du transport
                                        de personne en deux ou trois roues.
                                    </div>
                                    <div>
                                        <div className="text-center mb-3 mt-3">
                                            <div>
                                                <a href="https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/81534405600016_VMDTR_2022/81534405600016_VMDTR_2022?contexteFormation=ACTIVITE_PROFESSIONNELLE"
                                                   target="_blank"
                                                   className="theme-btn line-button-one contact-button button-orange">Financez
                                                    par votre CPF</a>
                                            </div>
                                        </div>
                                        <div className="text-center mb-3 mt-3">
                                            <div>
                                                <a href="/contact.html"
                                                   className="theme-btn line-button-one contact-button button-orange">En savoir plus<i
                                                        className="fa fa-angle-right" aria-hidden="true"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* /.container */}
                </section>
                {/* /.our-service */}
                {/* <section> */}

                <section className="action-banner-one pt-50 pb-50 md-pt-150 md-pb-100 pos-r">
                    <div className="theme-title-one text-center pb-50 md-pb-30">
                        <h2 className="main-title">LES SOLUTIONS DE FINANCEMENT</h2>
                        <hr className="title-divider wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms"
                            style={{
                                visibility: 'visible',
                                animationDuration: '1000ms',
                                animationDelay: '300ms',
                                animationName: 'fadeInUp'
                            }}/>
                    </div>
                    <div className="inner-wrapper pos-r">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 order-lg-last">
                                    <div className="theme-title-one">
                                        <div className="upper-title mb-5 color-orange">Comment Financer ma formation
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-push-3 col-xs-12">
                                        <ul className="text-left">
                                            <li>Financement par le biais du CPF</li>
                                            <li>Financement par le Pôle Emploi</li>
                                            <li>Financement par la Région IDF</li>
                                            <li>Financement personnel</li>
                                        </ul>
                                        <a href="financement.html"
                                           className="theme-btn line-button-one button-orange mt-50">En savoir plus
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xl-5  col-lg-6 ml-auto">
                                    <div className="theme-counter-five md-mt-70">
                                        <div className="screen-cpf img-shape wow zoomIn animated" data-wow-delay="0.89s"
                                             data-wow-duration="2s">
                                            <img src="assets/images/cpf/cpf.png" alt=""/>
                                        </div>
                                        <img style={{borderRadius: '30%'}} src="assets/images/home/fiancement_cpf.png"
                                             alt=""/>
                                        <div className="screen-pe img-shape wow zoomIn animated" data-wow-delay="0.89s"
                                             data-wow-duration="2s">
                                            <img src="assets/images/pole-emploi/pole-emploi.svg" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="testimonial-section-standard pos-r pt-100 pb-100 md-pt-70 md-pb-30">
                    <div className="theme-title-one text-center pb-50 md-pb-30">
                        <h2 className="main-title">Nos stagiaires ont la parole</h2>
                        <hr className="title-divider wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms"
                            style={{
                                visibility: 'visible',
                                animationDuration: '1000ms',
                                animationDelay: '300ms',
                                animationName: 'fadeInUp'
                            }}/>
                    </div>
                    <div className="container d-xl-flex align-items-md-stretch text-center">
                        <div className="order-lg-last">
                            <div className="customer-reviews">
                                <img style={{margin: "auto"}} src="assets/images/logo/isc-formation2.png" alt=""
                                     className="c-img"/>
                                <span className="span-review" aria-label="Note&nbsp;: 5,0 sur&nbsp;5"
                                      role="img"><span
                                    style={{width: 100 * googlereview.rating / 5 + "%"}}></span></span>
                                <div className="upper-title mb-5 color-orange">{googlereview.rating}/5 Basé sur</div>
                                <h5 className="main-title"><a
                                    href="https://www.google.com/search?q=isc+formation#lrd=0x47e676837183e9cf:0x18675ec38503bda8,1,,,"
                                    target="_blank">{googlereview.total} Avis de nos stagiaires</a></h5>
                            </div>
                        </div>
                        <div className="order-lg-first">
                            <AliceCarousel mouseTracking
                                           items={items}
                                           responsive={responsive}
                                           controlsStrategy="alternate"/>
                        </div>
                    </div>
                </section>
                <section className="container certifications mt-20">
                    <div className="theme-title-one text-center pb-50 md-pb-30">
                        <h2 className="main-title">Nos partenaires</h2>
                        <hr className="title-divider wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms"
                            style={{
                                visibility: 'visible',
                                animationDuration: '1000ms',
                                animationDelay: '300ms',
                                animationName: 'fadeInUp'
                            }}/>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-6 col-sm-12 text-center mb-6 mb-md-0">
                            <img src="assets/images/partners/motocab.png" alt=""/>
                        </div>
                        <div className="col-md-6 col-sm-12 text-center mb-6 mb-md-0">
                            <img src="assets/images/cma/cma_idf.png" alt=""/>
                        </div>
                    </div>
                </section>
                <section className="session-full pt-10 pt-md-14 pb-8 bg-gray-light mb-20">
                    <div className="container ">
                        <div className="section-content ">
                            {/* TITLE START*/}
                            <div className="section-head center wt-small-separator-outer text-center ">
                                <div className="wt-small-separator site-text-primary ">
                                </div>
                                <h2 className="main-title heading">Prochaines sessions</h2>
                                <hr className="title-divider wow fadeInUp" data-wow-delay="300ms"
                                    data-wow-duration="1000ms" style={{
                                    visibility: 'visible',
                                    animationDuration: '1000ms',
                                    animationDelay: '300ms',
                                    animationName: 'fadeInUp'
                                }}/>
                            </div>
                            {/* TITLE END*/}
                            <div className="row ">
                                <div className="col-lg-5 ">
                                    <div className="bg-white">
                                        <div className="service-box-title-new title-style-2 site-text-secondry ">
                                            <h5 className="fs-18 text-uppercase pt-1 mb-3">Contact Information</h5>
                                        </div>
                                        <p>
                                        </p>
                                        <div>Inscrivez-vous vite pour les prochains examens T3P (VTC, Taxi et Moto Taxi)
                                            !
                                        </div>
                                        <div>Vous pouvez vous inscrire à nos formations jusqu’à 30 jours avant le début
                                            de la formation.
                                        </div>
                                        <div>Pour les modalités d’inscriptions et les tarifs, merci de nous contacter
                                            par
                                        </div>
                                        <p/>
                                        <div className="py-2 ">
                                            <div className="widget recent-posts-entry ">
                                                <div><a href="#"><i className="fa fa-envelope mr-3"/>contact@iscformation.fr</a>
                                                </div>
                                                <div><a href="#"> <i className="fa fa-phone-alt mr-3"/>09 83 71 29 00
                                                </a></div>
                                                <div><a href="contact.html"><i className="fa fa-globe mr-3"/>Via notre
                                                    formulaire de contact</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 ">
                                    <div className="feature-block-three  bg-white ">
                                        <table className="table table-striped table-responsive table-sm">
                                            <thead>
                                            <tr>
                                                <th>
                                                    <div
                                                        className="service-box-title-new title-style-2 site-text-secondry pb-0 mb-0 ">
                                                        <h5 className="fs-18 text-uppercase  mb-3">Date de l'examen</h5>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="service-box-title-new title-style-2 site-text-secondry mb-0 pb-0 ">
                                                        <h5 className="fs-18 text-uppercase  mb-3">Date limite
                                                            d'inscription</h5>
                                                    </div>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Mardi 30 Avril 2024</td>
                                                <td>Vendredi 29 Mars 2024</td>
                                            </tr>
                                            <tr>
                                                <td>Mardi 28 Mai 2024</td>
                                                <td>Vendredi 26 Avril 2024</td>
                                            </tr>
                                            <tr>
                                                <td>Mardi 25 Juin 2024</td>
                                                <td>Vendredi 24 Mai 2024</td>
                                            </tr>
                                            <tr>
                                                <td>Mardi 24 Septembre 2024</td>
                                                <td>Vendredi 23 Août 2024</td>
                                            </tr>
                                            <tr>
                                                <td>Mardi 29 Octobre 2024</td>
                                                <td>Vendredi 27 Septembre 2024</td>
                                            </tr>
                                            <tr>
                                                <td>Mardi 19 Novembre 2024</td>
                                                <td>Vendredi 18 Octobre 2024</td>
                                            </tr>
                                            
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default Home
