import React, {useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import googlereview from "./googlereview/googlereview.json";
import AliceCarousel from "react-alice-carousel"
import {Fragment} from 'react'
import 'react-alice-carousel/lib/alice-carousel.css'

function Financement() {

    useEffect(() => {
        window.scroll(0, 0)
    }, []);
    const handleDragStart = (e) => e.preventDefault();
    const responsive = {
        0: {items: 1},
        568: {items: 2},
    };


    const items = googlereview.reviews.map((review) => {
        return (
            <div className="owl-item cloned" style={{
                width: "400px",
                marginRight: "0px"
            }}>
                <div className="item">
                    <div className="customer-content">
                        <img src="images/logo/c-1.png" alt=""/>
                        <p className="pt-15 pb-10 review-text">{review.text}</p>
                        <div className="d-flex align-items-center">
                            <img src={review.profile_photo_url} alt="" className="c-img"/>
                            <div>
                                <h6>{review.author_name}</h6>
                                <div>{new Intl.DateTimeFormat('fr-FR', {
                                    year: 'numeric',
                                    day: '2-digit',
                                    month: '2-digit'
                                }).format(review.time * 1000)}</div>
                                <span className="span-review" aria-label="Note&nbsp;: 5,0 sur&nbsp;5" role="img">
                                    <span style={{width: 100 * review.rating / 5 + "%"}}></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    });
    return (
        <Fragment>
            <div>
                <div className="rogan-hero-section rogan-hero-four pt-250 pb-150 md-pt-200 md-pb-130 pos-r">
                    <div className="shape-wrapper">
                        <svg className="img-shape shape-two">
                            <path fillRule="evenodd" fill="rgb(255, 223, 204)"
                                  d="M6.000,12.000 C9.314,12.000 12.000,9.313 12.000,6.000 C12.000,2.686 9.314,-0.000 6.000,-0.000 C2.686,-0.000 -0.000,2.686 -0.000,6.000 C-0.000,9.313 2.686,12.000 6.000,12.000 Z"/>
                        </svg>
                        <svg className="img-shape shape-three">
                            <path fillRule="evenodd" fill="rgb(198, 255, 224)"
                                  d="M12.500,24.999 C19.403,24.999 25.000,19.403 25.000,12.500 C25.000,5.596 19.403,-0.001 12.500,-0.001 C5.596,-0.001 -0.000,5.596 -0.000,12.500 C-0.000,19.403 5.596,24.999 12.500,24.999 Z"/>
                        </svg>
                        <svg className="img-shape shape-four">
                            <path fillRule="evenodd" opacity="0.451" fill="rgb(255, 126, 190)"
                                  d="M10.000,20.000 C15.523,20.000 20.000,15.522 20.000,10.000 C20.000,4.477 15.523,-0.000 10.000,-0.000 C4.477,-0.000 -0.000,4.477 -0.000,10.000 C-0.000,15.522 4.477,20.000 10.000,20.000 Z"/>
                        </svg>
                        <svg className="img-shape shape-five">
                            <path fillRule="evenodd" fill="rgb(181, 198, 255)"
                                  d="M6.000,12.000 C9.314,12.000 12.000,9.313 12.000,6.000 C12.000,2.686 9.314,-0.000 6.000,-0.000 C2.686,-0.000 -0.000,2.686 -0.000,6.000 C-0.000,9.313 2.686,12.000 6.000,12.000 Z"/>
                        </svg>
                        <svg className="img-shape shape-six">
                            <path fillRule="evenodd" fill="rgb(171, 247, 242)"
                                  d="M12.500,25.000 C19.404,25.000 25.000,19.403 25.000,12.500 C25.000,5.596 19.404,-0.000 12.500,-0.000 C5.596,-0.000 -0.000,5.596 -0.000,12.500 C-0.000,19.403 5.596,25.000 12.500,25.000 Z"/>
                        </svg>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className=" d-flex align-items-center ">
                                    <h3  className="main-title">QUELLES SONT LES DIFFERENTES FACON DE FINANCER MA FORMATION ?</h3>
                                    <div className=" wheel-text ">
                                        Vous avez plusieurs options qui s’offrent à vous, effectivement, vous pouvez mobiliser votre Compte Formation, passer par la région IDF si vous êtes domiciliés en Région IDF,<br/>
                                        passer par le Pôle Emploi ou encore bénéficier d’un financement en plusieurs échéances si vous passez par un paiement personnel.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-us-block-two pt-50 pb-50 md-pt-150 md-pb-100 pos-r">
                            <div className="inner-wrapper pos-r">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 col-lg-6 ml-auto">
                                            <div className="theme-title-one">
                                                <h3 className="main-title">LE FINANCEMENT PAR CPF</h3>
                                            </div>
                                            <p className="pb-25"><br/>
                                                <p className="pb-25">
                                                    <p>Depuis 2020, si vous avez travaillé en tant que salarié, vous avez cumulé des points CPF.</p>
                                                    <p>Vous pouvez utiliser ces points pour financer vos formations éligibles au CPF sans avoir à ajouter de frais* de formation si vos points couvrent l’ensemble du montant de la formation choisie.</p>
                                                    *Les frais d’inscription ne seront pas inclus.
                                                </p>
                                                <a href="contact.html"
                                                   className="theme-btn line-button-one button-orange mt-25">je m'inscris <i
                                                    className="fa fa-angle-right" aria-hidden="true"/></a>
                                            </p>
                                        </div>
                                        <div className="col-xl-6  order-lg-last">
                                            <div className="theme-counter-five">
                                                <div className="inner-wrapper pos-r">
                                                    <div className="shape-wrapper shape-wrapper-financement-cpf">
                                                        <svg className="img-shape dot-two">
                                                            <path fillRule="evenodd" fill="rgb(255, 181, 106)"
                                                                  d="M13.000,26.000 C20.180,26.000 26.000,20.179 26.000,12.999 C26.000,5.820 20.180,-0.001 13.000,-0.001 C5.820,-0.001 -0.000,5.820 -0.000,12.999 C-0.000,20.179 5.820,26.000 13.000,26.000 Z"/>
                                                        </svg>
                                                        <svg className="img-shape dot-three">
                                                            <path fillRule="evenodd" fill="rgb(136, 217, 255)"
                                                                  d="M4.500,8.999 C6.985,8.999 9.000,6.985 9.000,4.500 C9.000,2.014 6.985,-0.000 4.500,-0.000 C2.015,-0.000 -0.000,2.014 -0.000,4.500 C-0.000,6.985 2.015,8.999 4.500,8.999 Z"/>
                                                        </svg>
                                                        <svg className="img-shape dot-four">
                                                            <path fillRule="evenodd" fill="rgb(255, 108, 196)"
                                                                  d="M10.000,20.000 C15.523,20.000 20.000,15.522 20.000,10.000 C20.000,4.477 15.523,-0.000 10.000,-0.000 C4.477,-0.000 -0.000,4.477 -0.000,10.000 C-0.000,15.522 4.477,20.000 10.000,20.000 Z"/>
                                                        </svg>
                                                        <svg className="img-shape dot-five">
                                                            <path fillRule="evenodd" fill="rgb(119, 244, 245)"
                                                                  d="M4.500,8.999 C6.985,8.999 9.000,6.985 9.000,4.500 C9.000,2.014 6.985,-0.000 4.500,-0.000 C2.015,-0.000 -0.000,2.014 -0.000,4.500 C-0.000,6.985 2.015,8.999 4.500,8.999 Z"/>
                                                        </svg>
                                                        <svg className="img-shape dot-six">
                                                            <path fillRule="evenodd" fill="rgb(165, 149, 255)"
                                                                  d="M15.000,30.000 C23.284,30.000 30.000,23.284 30.000,15.000 C30.000,6.715 23.284,-0.000 15.000,-0.000 C6.716,-0.000 -0.000,6.715 -0.000,15.000 C-0.000,23.284 6.716,30.000 15.000,30.000 Z"/>
                                                        </svg>
                                                        <svg className="img-shape dot-seven">
                                                            <path fillRule="evenodd" fill="rgb(102, 225, 158)"
                                                                  d="M5.500,11.000 C8.537,11.000 11.000,8.537 11.000,5.500 C11.000,2.462 8.537,-0.001 5.500,-0.001 C2.462,-0.001 -0.000,2.462 -0.000,5.500 C-0.000,8.537 2.462,11.000 5.500,11.000 Z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /.theme-counter-five */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 order-lg-last">
                                            <div className="theme-title-one">
                                                <h3 className="main-title">LE FINANCEMENT PAR LA REGION IDF</h3>
                                            </div>
                                            {/* /.theme-title-one */}
                                            {/* <div className="mark-text pt-25 pb-35">Creative & Proffesional Digital</div> */}
                                            <p className="pb-25">
                                                <p>Si vous avez épuisé tous vos points CPF, que vous êtes inscrit au Pôle Emploi et que vous résidez en IDF, <br/>
                                                    nous pouvons formuler une demande de prise en charge à la Région IDF pour financer vos frais de formation*.</p>
                                                    *Les frais d’inscription ne seront pas inclus.
                                            </p>
                                                <a href="contact.html"
                                               className="theme-btn line-button-one button-orange mt-25">je m'inscris <i
                                                className="fa fa-angle-right" aria-hidden="true"/></a>
                                        </div>
                                        <div className="col-xl-6  col-lg-6 ml-auto">
                                            <div className="theme-counter-five md-mt-70">
                                                <div className="inner-wrapper pos-r">
                                                    <div className="shape-wrapper shape-wrapper-financement-pole-emploi">
                                                        <svg className="img-shape dot-two">
                                                            <path fillRule="evenodd" fill="rgb(255, 181, 106)"
                                                                  d="M13.000,26.000 C20.180,26.000 26.000,20.179 26.000,12.999 C26.000,5.820 20.180,-0.001 13.000,-0.001 C5.820,-0.001 -0.000,5.820 -0.000,12.999 C-0.000,20.179 5.820,26.000 13.000,26.000 Z"/>
                                                        </svg>
                                                        <svg className="img-shape dot-three">
                                                            <path fillRule="evenodd" fill="rgb(136, 217, 255)"
                                                                  d="M4.500,8.999 C6.985,8.999 9.000,6.985 9.000,4.500 C9.000,2.014 6.985,-0.000 4.500,-0.000 C2.015,-0.000 -0.000,2.014 -0.000,4.500 C-0.000,6.985 2.015,8.999 4.500,8.999 Z"/>
                                                        </svg>
                                                        <svg className="img-shape dot-four">
                                                            <path fillRule="evenodd" fill="rgb(255, 108, 196)"
                                                                  d="M10.000,20.000 C15.523,20.000 20.000,15.522 20.000,10.000 C20.000,4.477 15.523,-0.000 10.000,-0.000 C4.477,-0.000 -0.000,4.477 -0.000,10.000 C-0.000,15.522 4.477,20.000 10.000,20.000 Z"/>
                                                        </svg>
                                                        <svg className="img-shape dot-five">
                                                            <path fillRule="evenodd" fill="rgb(119, 244, 245)"
                                                                  d="M4.500,8.999 C6.985,8.999 9.000,6.985 9.000,4.500 C9.000,2.014 6.985,-0.000 4.500,-0.000 C2.015,-0.000 -0.000,2.014 -0.000,4.500 C-0.000,6.985 2.015,8.999 4.500,8.999 Z"/>
                                                        </svg>
                                                        <svg className="img-shape dot-six">
                                                            <path fillRule="evenodd" fill="rgb(165, 149, 255)"
                                                                  d="M15.000,30.000 C23.284,30.000 30.000,23.284 30.000,15.000 C30.000,6.715 23.284,-0.000 15.000,-0.000 C6.716,-0.000 -0.000,6.715 -0.000,15.000 C-0.000,23.284 6.716,30.000 15.000,30.000 Z"/>
                                                        </svg>
                                                        <svg className="img-shape dot-seven">
                                                            <path fillRule="evenodd" fill="rgb(102, 225, 158)"
                                                                  d="M5.500,11.000 C8.537,11.000 11.000,8.537 11.000,5.500 C11.000,2.462 8.537,-0.001 5.500,-0.001 C2.462,-0.001 -0.000,2.462 -0.000,5.500 C-0.000,8.537 2.462,11.000 5.500,11.000 Z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /.theme-counter-five */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-lg-6 ml-auto">
                                            <div className="theme-title-one">
                                                <h3 className="main-title">LE FINANCEMENT PERSONNEL</h3>
                                            </div>
                                            <p className="pb-25">
                                                Si vous n’êtes concerné par aucun des financements cités ci-dessus, <br/>
                                                 vous pouvez opter pour un financement personnel, vous pourrez payer en une ou deux fois sans frais.
                                            </p>
                                        </div>
                                        <div className="col-xl-6  order-lg-last">
                                            <div className="theme-counter-five md-mt-70">
                                                <div className="inner-wrapper pos-r">
                                                    <div className="shape-wrapper shape-wrapper-financement-personnel">
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /.theme-counter-five */}
                                        </div>
                                    </div>
                                    <div className="row mt-120">
                                        <div className="col">
                                            <div className=" d-flex align-items-center ">
                                                <div className=" wheel-text ">
                                                    Si vous passez par le Pôle Emploi ou la Région IDF, merci de nous contacter au moins 1 mois et demi avant le début de la formation
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.container */}
                            </div>
                            {/* /.inner-wrapper */}
                        </div>
                    </div>

                </div>

            </div>
        </Fragment>
    )
}

export default Financement
