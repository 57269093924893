import React, {useEffect} from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    Link
} from "react-router-dom";
import Home from '../components/Home'
import Contact from '../components/Contact'
import BlogDetail from '../components/BlogDetail'
import Financement from '../components/Financement'
import Ressources from '../components/Ressources'
import Vtc from '../components/Vtc'
import Faq from '../components/Faq';
import Taxi from '../components/Taxi';
import TaxiMoto from '../components/TaxiMoto';
import Capacite from '../components/Capa';
function Header() {
    return (
        <Router>
            <section>
                <div id="preloader">
                    <div id="ctn-preloader" className="ctn-preloader">
                        <div className="animation-preloader">
                            <div className="icon"><img src="images/1.svg" alt=""/></div>
                            <div className="txt-loading">
                        <span data-text-preloader="I" className="letters-loading">
                            I
                        </span>
                                <span data-text-preloader="S" className="letters-loading">
                            S
                        </span>
                                <span data-text-preloader="C" className="letters-loading">
                            C
                        </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="theme-main-menu theme-menu-one main-orange-color sticky-menu">
                <div className="d-flex align-items-center">
                    <div className="logo mr-auto"><Link to="/"><img src="assets/images/logo/isc-formation2.png" alt=""/></Link>
                    </div>
                    <div className="right-content order-lg-3">
                        <ul className="d-flex align-items-center button-contact">
                            <li className="navbar-nav">
                                <Link to="/financement" className="nav-item">FINANCEMENT</Link>
                            </li>
                            <li className="navbar-nav">
                                <Link to="/contact" className="nav-item">NOUS CONTACTER</Link>
                            </li>
                            <li className="navbar-nav">
                                <a href="https://www.elearning.iscformation.fr" target="_blank" className="nav-item">MON COMPTE</a>
                            </li>
                        </ul>
                    </div>
                    <nav id="mega-menu-holder" className="navbar navbar-expand-lg order-lg-2">
                        <div className="container nav-container">
                            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <i className="flaticon-setup"/>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/"></Link>
                                    </li>
                                    <li className="nav-item ">
                                        <Link className="nav-link" to="/formation-vtc-elearning">VTC</Link>
                                    </li>
                                    <li className="nav-item  icon-menu">
                                        <Link className="nav-link" to="/formation-taxi-elearning">TAXI</Link>
                                    </li>
                                    <li className="nav-item  icon-menu">
                                        <Link className="nav-link" to="/formation-taxi-moto-elearning">VMDTR</Link>
                                    </li>
                                
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            {/* /.theme-main-menu */}


            <Switch>

                <Route exact path="/"  component={Home}/>
                <Route exact path="/formation-vtc-elearning" component={Vtc}/>
                <Route exact path="/faq" component={Faq}/>
                <Route exact path="/formation-taxi-elearning" component={Taxi}/>
                <Route exact path="/formation-taxi-moto-elearning" component={TaxiMoto}/>
                <Route exact path="/formation-transport-elearning" component={Capacite}/>
                <Route exact path="/financement" component={Financement}/>
                <Route exact path="/ressources" component={Ressources}/>
                <Route exact path="/contact" component={Contact}/>
                <Route exact path="/blog-detail" component={BlogDetail}/>


            </Switch>

        </Router>
    )
}

export default Header
